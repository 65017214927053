$dark-primary: rgb(28, 28, 28);

$tab: 767px;

@mixin tab {
  @media screen and (max-width: $tab) {
    @content;
  }
}

.globalInner {
  max-width: 1075px;
  margin: 0 auto;
  box-sizing: content-box;
}

.dialog-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  border-bottom: none !important;
}

.pc-none {
  display: inline-block !important;

  @include tab {
    display: none !important;
  }
}
.sp-none {
  display: none !important;
  @include tab {
    display: inline-block !important;
  }
}

// Mixin for fadeInUp animation
@mixin fadeInUp($duration: 2s, $delay: 0.4s) {
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  animation-name: fadeInUp;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-fill-mode: both;
}

// Applying the animation to a class
.fadeInUp {
  @include fadeInUp();
}

.request-btn-primary {
  max-width: 250px;
  @include tab {
    width: 100%;
    max-width: none;
  }
}
