@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.roster_root {
  background-color: #000;
  min-height: 100vh;
}

.roster_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  color: #fff;
}

.roster_header {
  margin-bottom: 50px;
}

.roster_card {
  width: 100%;
  height: 67px;
  border-radius: 10px;
  background-color: #2d2d2d;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.roster_card_s {
  width: 100%;
  min-height: 67px;
  border-radius: 10px;
  background-color: #2d2d2d;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  padding: 10px 0;
  justify-content: space-between;
}

.roster_card_s__disabled {
  opacity: 0.6;
  pointer-events: none;
}

.roster_time {
  width: 80px;
  height: 23.66px;
  border-radius: 200px;
  background: #aafaa1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}

.roster_function_button {
  width: 290px !important;
  height: 50px !important;
  border-radius: 400px !important;
  background-color: #ffffff !important;
  font-family: 'Inter', sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #000000 !important;
  text-transform: capitalize !important;
  margin-top: 20px !important;
}

.roster_function_button__disabled {
  background: #cccccc !important;
  cursor: default;
}

.roster_button,
.roster_button_addnote {
  width: 68px !important;
  height: 68px !important;
  border-radius: 5px !important;
  color: #fff !important;
  padding: 2px !important;
  font-size: 10px !important;
}

.roster_button_black {
  color: #000 !important;
}
.roster_button_white {
  color: #fff !important;
}

.chip-wrapper {
  margin-right: 10px;
  flex-direction: column;
  display: flex;
}

.chip_base {
  min-width: 86px;
  justify-content: left !important;
  height: 24px !important;
  font-size: 12px !important;
  color: #fff !important;
}

.chip_base + .chip_base {
  margin-top: 4px;
}

.chip_black {
  color: #000 !important;
}
.chip_white {
  color: #fff !important;
}

.roster_button_addnote {
  background-color: #2a5bd9 !important;
}
