@import 'events/common';

.ticket {
  animation: bouncingCard 0.6s ease-out infinite alternate;
  background-color: #ffffff;
  color: darkslategray;
  border-radius: 12px;
  margin: 5px;
  .ticketImage {
    width: 100%;
    height: 180px;
    display: block;
  }
}

.ticketTitle {
  font-size: 1.2rem;
  font-weight: 700;
  padding: 12px 16px 4px;
  border-bottom: 1px inset #edeade;
}
.ticketDetail {
  font-size: 1rem;
  padding: 4px 16px;
  height: 150px;
}
.ticketSubDetail {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  padding: 12px 16px;
  font-weight: bold;
}
.ticketSubDetail .code {
  margin-right: 24px;
  font-weight: bold;
}

/* Ticket Ripper */
.ticketRip {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.circleLeft {
  width: 12px;
  height: 24px;
  background-color: #edeade;
  border-radius: 0 12px 12px 0;
}
.ripLine {
  width: 100%;
  border-top: 3px solid #edeade;
  border-top-style: dashed;
}
.circleRight {
  width: 12px;
  height: 24px;
  background-color: #edeade;
  border-radius: 12px 0 0 12px;
}
