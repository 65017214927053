@import 'common';

.carouselItem {
  height: 400px;
  width: auto;
  object-fit: cover;
  margin: 0 auto;
}
.typo-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  bottom: 0;
  width: calc(100% - 32px);
  border-radius: 50%;
  margin-left: 16px;
  margin-bottom: 16px;
  z-index: 4;
}
.leftButton {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
}
.rightButton {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
}
.content {
  display: flex;
  flex-direction: column;
}
.title {
  font-size: 16px;
}
.description {
  font-size: 14px;
}
.date {
  font-size: 12px;
}

.post-card + .post-card {
  margin-top: 20px;
}
.post-card.portrait {
  .image-wrapper {
    position: relative;
    &:before {
      content: '';
      width: 100%;
      display: block;
      position: absolute;
      bottom: 0;
      height: 90px;
      z-index: 2;
      box-shadow: 0px -161px 47px -125px rgba(0, 0, 0, 0.75) inset;
    }
  }
}
.image-wrapper_content {
  height: 400px;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 14px !important;
}
.carouselItem {
  border-radius: 14px;
}

// Style for Icon Layout
.grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  .typo-wrapper {
    position: static;
    margin-left: 0px;
    margin-bottom: 0px;
    width: 100%;
  }
  .type-wrapper_title {
    @media (min-width: 768px) {
      font-size: 22px !important;
    }
  }
  .typo-wrapper_date {
    color: #666 !important;
  }
  .image-wrapper_arrows {
    display: none;
  }
  .post-card {
    margin-top: 8px;
    width: calc(50% - 8px);
  }
  .image-wrapper_content {
    height: auto;
    aspect-ratio: 1;
  }
  .carouselItem {
    object-fit: cover;
    width: 100%;
    height: 100%;
    aspect-ratio: 1;

    video {
      width: 100% !important;
      height: auto !important;
    }
  }
}

// Style for list layout
.list {
  .image-wrapper_arrows {
    display: none;
  }
  .post-card {
    margin-top: 32px;
    @media (max-width: 767px) {
      margin-top: 14px;
    }
  }
  .post-card_contents {
    display: flex;
    gap: 16px;
    align-items: flex-start;

    @media (max-width: 767px) {
      gap: 16px;
    }
  }
  .typo-wrapper {
    position: static;
    margin: 0;
    width: 100%;
  }

  .image-wrapper_content {
    width: 150px;
    height: 150px;
    @media (max-width: 767px) {
      width: 100px;
      height: 100px;
    }
    a {
      display: block;
    }
  }
  .image-wrapper_content {
    .carouselItem {
      object-fit: cover;
      width: 150px;
      height: 150px;
      @media (max-width: 767px) {
        width: 105px;
        height: 105px;
      }
    }
  }
}
