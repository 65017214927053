.booking_widget__app {
  display: block;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  width: 769px;
  padding: 38px 46px 7px 46px;
  margin: 10px auto;
  max-width: 100%;
}

.booking_widget__app.desktop {
  margin: 100px auto;
}

.root_form {
  box-shadow: -1px 5px 10px -1px rgba(0, 0, 0, 0.28);
  -webkit-box-shadow: -1px 5px 10px -1px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: -1px 5px 10px -1px rgba(0, 0, 0, 0.28);
}

@media only screen and (max-width: 491px) {
  .root_form {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }

  form .MuiFormControl-root:first-child:nth-child(1),
  form .MuiFormControl-root:nth-child(3) {
    /* border: 1px solid #cccccc; */
    box-shadow: -1px 5px 10px -1px rgba(0, 0, 0, 0.28);
    -webkit-box-shadow: -1px 5px 10px -1px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: -1px 5px 10px -1px rgba(0, 0, 0, 0.28);
  }
}
@media only screen and (max-width: 378px) {
  .root_form {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }

  form .MuiFormControl-root:first-child:nth-child(1),
  form .MuiFormControl-root:nth-child(3) {
    /* border: 1px solid #cccccc; */
    box-shadow: -1px 5px 10px -1px rgba(0, 0, 0, 0.28);
    -webkit-box-shadow: -1px 5px 10px -1px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: -1px 5px 10px -1px rgba(0, 0, 0, 0.28);
  }
}

.booking_widget__option {
  display: block;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 10px 10px 25px 10px;
  border-radius: 10px;
  width: 500px;
  max-width: 100%;
  margin: 100px auto;
  opacity: 0.8;
}

.booking_widget__option .lbl {
  color: white;
}

.booking_widget__option .header {
  width: 100%;
  margin: 0;
}

.booking_widget__option .content {
  text-align: center;
  width: 100%;
  margin: 0;
  margin-top: 20px;
}

.booking_widget__option .content .left {
  text-align: -webkit-right;
}

.booking_widget__option .content .right {
  text-align: -webkit-left;
}

.booking_widget__option .header .left {
  width: 50%;
}

.booking_widget__option .header .right {
  width: 50%;
  text-align: right;
  padding-right: 11px;
  height: 24px;
}

.close_btn {
  width: 12px;
  height: 12px;
  filter: invert(1);
  cursor: pointer;
}

.bg-option label {
  font-size: 12px;
  color: white;
  font-weight: 700;
  margin-top: 43px;
  cursor: pointer;
  user-select: none;
}

.bg-option:active {
  filter: grayscale(0.6);
}

.bg-option:hover {
  cursor: pointer;
}

.bg-option {
  text-align: center;
  width: 150px;
  height: 100px;
  border-radius: 10px;
  background: radial-gradient(circle farthest-corner at 201% 202%, #f2dfaa 29%, #e8437e);
}

.experience-item {
  background: radial-gradient(circle farthest-corner at 201% 202%, #f2dfaa 29%, #e8437e);
}

.myForm {
  width: 100%;
  background-color: #dfe6e9;
  border-radius: 5px;
  padding: 20px;
}

.formField {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: block;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.myButton {
  width: 100%;
  background-color: #00b894;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.myList {
  list-style: none;
}

.myListButton {
  display: inline-block;
  height: auto;
  width: auto;
  border-radius: 7px;
  border: 1px solid #00b894;
  margin: 3px 5px;
}

.MuiButton-contained.Mui-disabled {
  /* text-indent: 999%; */
  white-space: nowrap;
  overflow: hidden;
}

.MuiButton-containedPrimary {
  margin-right: 15px !important;
  /* background-color: #fe7d86 !important; */
  background: rgb(250, 81, 134);
  background: linear-gradient(90deg, rgba(250, 81, 134, 1) 0%, rgba(243, 214, 160, 1) 100%);
}

.MuiButton-containedPrimary.no-margin-right {
  margin-right: 0px !important;
}

.MuiButton-containedPrimary.unselected {
  background: #242a2e;
}

.MuiButton-containedPrimary:hover {
  background-color: #fe7d86 !important;
}

.MuiButton-containedPrimary.confirm:hover {
  background-color: #27da8c !important;
}

.MuiButton-root.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
  background: #cccccc !important;
}

form {
  margin-bottom: 25px;
}

.MuiInputBase-input {
  padding: 20px 20px 20px !important;
}

.DayPickerInput input {
  min-height: 61px;
  /* border: 1px solid #cccccc; */
  border: none;
  border-left: 0px solid;
  border-right: 0px solid;
  padding: 0;
  margin: 0;
  padding-left: 18px;
  font-size: 16px;
  color: #707070;
  /* max-width: 150px; */
}

.MuiInput-underline:before,
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border: none !important;
}

form .MuiFormControl-root:first-child:nth-child(1),
form .MuiFormControl-root:nth-child(3) {
  /* border: 1px solid #cccccc; */
}

form .MuiFormControl-root:first-child:nth-child(1) .MuiInput-input,
form .MuiFormControl-root:nth-child(3) .MuiInput-input {
  font-size: 16px;
  color: #707070;
}

form .MuiFormControl-root:first-child:nth-child(1) {
  /* border: 1px solid #cccccc; */
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

form .MuiButtonBase-root {
  border-radius: 0px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: none;
  text-transform: initial;
  padding-left: 29px;
  padding-right: 30px;
  margin-right: 0 !important;
}

form .MuiButtonBase-root .MuiButton-label {
  font-size: 16px;
}

form .MuiInput-underline:after {
  border-bottom: 0px;
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}

.booking_widget__powered_by {
  color: #707070;
  font-size: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .Step1-formControl-2 {
  min-width: 120px;
  max-width: 169px;
  flex-grow: 1;
} */

.booking_widget__app .content {
  max-width: 676px;
  margin: 0 auto;
}

.booking_widget__app .content h2 {
  font-size: 20px;
}

.booking_widget__option .options {
  max-width: 500px;
  margin: 0 auto;
}

.radio button {
  padding: 8px 18px;
  border-radius: 10px;
}

.radio,
.sweet-loading {
  margin-bottom: 30px;
}

.radio button:last-child {
  margin-right: 0 !important;
}

.sub-head {
  display: inline-flex;
  padding: 0;
  width: 100%;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 45px;
  margin-top: 20px;
}

.sub-head li {
  display: inline-flex;
  /* margin-right: 15px; */
  font-size: 16px;
  text-transform: uppercase;
  color: #707070;
  padding-bottom: 15px;
  font-weight: 500;
  bottom: -1px;
  position: relative;
  width: 100%;
}

.sub-head li:first-child {
  color: #fe7d86;
  border-bottom: 2px solid #fe7d86;
}

.section-title {
  font-size: 16px;
  text-transform: uppercase;
  color: #707070;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 16px;
  font-weight: 500;
}

button:focus {
  outline: 0 auto -webkit-focus-ring-color !important;
}

input:focus {
  outline: -webkit-focus-ring-color auto 0;
}

@media only screen and (max-width: 769px) {
  .MuiSelect-icon {
    right: 15px !important;
  }

  .booking_widget__app {
    padding: 38px 15px 7px 15px;
    background-color: #f7f7f7;
  }

  .step1 .MuiFormControl-root {
    max-width: 100%;
    width: 100% !important;
    border-radius: 30px;
    margin-bottom: 10px !important;
    background-color: white;
  }

  .DayPickerInput {
    border-radius: 28px;
    box-shadow: -1px 5px 10px -1px rgba(0, 0, 0, 0.28);
    -webkit-box-shadow: -1px 5px 10px -1px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: -1px 5px 10px -1px rgba(0, 0, 0, 0.28);
  }

  .DayPickerInput input {
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    border-radius: 30px;
    max-width: 100%;
    width: 100%;
    border: none;
  }

  button.submit {
    border-radius: 30px;
    width: 100%;
    height: 60px;
    margin-top: 5px;
  }

  .radio {
    text-align: center;
    display: inline-flex;
    overflow-x: auto;
    max-width: 100%;
  }

  .radio button {
    /* margin-bottom: 25px; */
    margin-bottom: 10px;
    min-width: 89px;
  }

  .step1 form {
    margin-bottom: 30px;
  }

  form .MuiFormControl-root {
    box-shadow: -1px 5px 10px -1px rgba(0, 0, 0, 0.28);
    -webkit-box-shadow: -1px 5px 10px -1px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: -1px 5px 10px -1px rgba(0, 0, 0, 0.28);
  }
}

@media only screen and (max-width: 491px) {
  .radio .MuiButton-containedPrimary:nth-child(3) {
    /* margin-right: 0 !important; */
  }
}

@media only screen and (max-width: 378px) {
  .radio .MuiButton-containedPrimary:nth-child(2),
  .radio .MuiButton-containedPrimary:nth-child(4) {
    /* margin-right: 0 !important; */
  }

  .radio .MuiButton-containedPrimary:nth-child(3) {
    /* border: 1px solid #cccccc; */
    /* margin-right: 15px !important; */
  }
}

.futureDates h5 {
  padding-bottom: 10px;
}

.MuiMenu-list {
  max-height: 200px;
}

.booking_widget__app.step2 {
  box-shadow: none;
  margin-top: 40px;
  width: 100%;
  max-width: 500px;
  margin: 40px auto;
  padding: 0 30px;
  background: inherit;
  label {
    margin-bottom: 0px;
  }
}

.step2.booking_widget__app .check-container .check-icon {
  width: 80px;
  height: 80px;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.step2.booking_widget__app .check-container .check-step {
  text-align: center;
}
.step2.booking_widget__app .check-container .check-text {
  font-size: 12px;
}

.step2 h1 {
  color: #222a2f;
  font-size: 24px;
  letter-spacing: 0px;
  margin-bottom: 18px;
}

.step2 h2 {
  font-family: 'acumin-pro', sans-serif;
  color: #222a2f;
  font-size: 20px;
  letter-spacing: 0px;
  margin-bottom: 0px;
  line-height: 1.3em;
}

.step2 .icons {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  &:last-child {
    margin-bottom: 0;
  }
}

.step2 .icons span {
  display: inline;
  vertical-align: middle;
}

.step2 .info-container {
  border: 1px solid #222a2f;
  width: 100%;
  padding: 30px;
  margin: 20px auto;
}
.ticket-form .info-container {
  width: 100%;
  height: auto;
}

.step2 .info-container.experience {
  padding: 12px 5px 5px 22px;
}

.step2 h3 {
  color: #222a2f;
  font-size: 16px;
  margin: 0;
  font-family: 'acumin-pro', sans-serif;
}

.step2 p {
  color: #707070;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 1.2em;
}

.step2 .counter {
  color: #222a2f;
  margin-bottom: 40px;
  font-size: 14px;
}

.step2 .afterHrh2 {
  margin-bottom: 8px;
}

.step2 .submit {
  padding: 10px 20px;
}

.step2 input {
  padding: 5px 15px;
  height: 40px;
  font-size: 16px;
  border-radius: 10px;
  color: #707070;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.step2 .footer {
  color: #707070;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 1.2em;
  font-size: 12px;
  margin-bottom: 5px;
}

.booking_widget__powered_by {
  color: #707070;
  font-size: 12px !important;
  text-align: center;
  margin-top: 10px;
}
