@import 'events/common';

.table-floor-container {
  width: 100%;
  height: 100%;
  .table-floor-separator {
    width: 100%;
    height: 6px;
    border-radius: 25px;
    background: #222a2f;
    box-shadow: inset 1px 1px 2px #1a2024, inset -1px -1px 2px #2a343a;
  }

  .table-floor-bottom {
    width: 100%;
    height: 40px;
    justify-content: flex-end;
    display: inline-flex;

    .btn-floor {
      background: rgba(0, 0, 0, 0.8);
      cursor: pointer;
      display: inline-flex;
      padding: 8px 15px;
      color: white;
      margin-right: 10px;
      border-top-left-radius: 10px;
      font-size: 14px;

      .btn-floor-name {
        margin: 0 10px;
      }
    }

    .btn-timeline {
      width: 58px;
      height: 40px;
      background: rgba($color: #000000, $alpha: 0.8);
      padding-top: 6px;
      padding-left: 17px;
      border-top-left-radius: 10px;
      cursor: pointer;
    }
  }
}

.table-floor {
  width: 100%;
  height: 90%;
  position: relative;
  overflow: auto;
  // flex-direction: row-reverse;
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;

  .table-container {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
    cursor: pointer;

    &.unavailable {
      pointer-events: none;
      cursor: none;
      filter: contrast(0.2);
    }
  }
}
