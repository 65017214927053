@import 'common';

.swiperWrapper {
  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .videoPrayer {
    width: 100% !important;
    height: 100% !important;
  }
  height: 400px;
}

.back {
  position: absolute;
  left: 16px;
  top: 16px;
  z-index: 4;
  width: auto !important;
}
.title {
  font-size: 16px;
}
.event-description {
  font-size: 14px;
}
.event-date {
  font-size: 12px;
}
.fixed-items {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 16px 16px 32px;
  z-index: 100;
  background: #000;
}
.loading {
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
  color: rgb(255, 255, 255);
  font-size: 40px;
  animation: spinner 1.5s linear infinite;
}
.detail-title {
  color: #fff !important;
  font-weight: bold;
  position: absolute;
  bottom: 8px;
  left: 16px;
  z-index: 4;
  font-size: 53px;
}

.functionButton {
  height: 45px;
  font-size: 14px !important;
  font-weight: bold !important;
  width: 100%;
  border-radius: 60px !important;
  display: block;
  text-transform: none !important;
}

.eventContent-wrapper {
  padding: 75px 40px;
}
.eventContent-wrapper.v3 {
  padding-bottom: 60px;
}

.date-wrapper {
  display: flex;
  flex-flow: column;
  width: 48px;
  height: 48px;
  background-color: #242424 !important;
  align-items: center;
  justify-content: center;
}
.map-wrapper {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 24px;
}

.filter-wrapper {
  transition: 0.3s;
  max-height: 100000px;
  min-height: 480px;

  .close-button {
    position: absolute;
    width: 60px;
    height: 5px;
    padding: 0;

    border-radius: 100px;
    top: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 2;
  }
}

.changeDisplay {
  width: 100px;
  height: 100px;
  opacity: 0;
  position: fixed !important;
  z-index: 100;
  top: 0;
  right: 0;
}

.MuiPickersToolbarText-toolbarBtnSelected {
  font-size: 24px !important;
}

.swiperWrapper {
  position: relative;
  &:before {
    content: '';
    width: 100%;
    display: block;
    position: absolute;
    bottom: 0;
    height: 60px;
    z-index: 2;
    box-shadow: 0px -148px 36px -126px rgba(0, 0, 0, 0.75) inset;
  }
  .swiper-pagination-bullet {
    background: #fff !important;
  }
  .swiper-pagination-bullet-active {
    background: #fff !important;
  }
}

.filter-drawer {
  .MuiPaper-root {
    background-color: transparent;
  }
}

.tip-list + .tip-list {
  margin-top: 8px;
}

.allWrapper {
  padding: 16px;
  #date-picker-inline {
    text-align: left;
    cursor: pointer;
  }
  .filterItem-wrapper {
    @media (max-width: 767px) {
      padding: 0 10px;
    }
  }
  .filterItem-wrapper > div {
    width: 100%;

    padding: 4px 0px;
  }

  .datepicker {
    width: 100%;
    padding-right: 4px !important;
  }
  .partySizeSelect {
    width: 100%;
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid #fff !important;
  }

  .MuiSelect-select.MuiSelect-select {
    text-align: left;
    width: 100%;
    z-index: 100;
  }

  .MuiSelect-selectMenu {
    text-align: center;
  }
  .MuiInputBase-input {
    padding: 0 !important;
    font-size: 16px !important;
  }
  .selectIcon {
    position: absolute;
    right: 0;
    top: -9px;
    margin-right: 0 !important;
  }
  .MuiInputAdornment-positionEnd {
    margin-left: 3px !important;
  }

  .MuiIconButton-root {
    padding: 0 !important;
  }

  .MuiSvgIcon-root {
    font-size: 20px !important;
    position: relative;
    right: -3px;
    top: -4px;
  }
  .MuiPopover-paper {
    left: 0 !important;
    right: 0 !important;
    margin: auto;
    display: table;
  }
  .MuiMenu-list {
    overflow-y: scroll;
    background-color: #1c1c1c;
  }
  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background-color: rgba(0, 0, 0, 0.8) !important;
  }
  .MuiMenuItem-root {
    color: #fff !important;
  }
  .MuiPickersDay-daySelected {
    background-color: #fff !important;
  }
}
.allWrapper.allWrapper-aside {
  padding: 0;
}
.allWrapper.allWrapper-dialog {
  padding: 0;

  max-height: calc(100vh - 64px);
}
.bookFunction-wrapper {
  position: relative;
  position: fixed !important;
  z-index: 999;
  width: calc(100% - 40px);
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  &::before {
    content: '';
    width: calc(100% + 40px);
    height: 65px;
    // background-color: #000;
    position: absolute;
    left: 50%;
    bottom: -10px;
    transform: translateX(-50%);
  }
}

.detail-kv {
  position: relative;
  z-index: 1;
  &__icon {
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 111;
    text-align: center;
    width: 100px;
  }
  &__link-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    // color: #000;
    height: 30px;
    margin-top: 5px !important;
    font-size: 12px !important;
    font-weight: bold !important;
    width: 100%;
    border-radius: 30px !important;
    text-transform: none !important;
    background-color: #fff !important;
  }
}

// Desktop View
@media (min-width: 768px) {
  .detail-title {
    display: none;
  }
  .bookFunction-wrapper {
    display: none;
  }
  .event-summary {
    flex-direction: column;
    .event-title {
      font-size: 53px;
      font-weight: bold;
      line-height: 1.2;
      order: 0;
    }
    .event-description {
      order: 2;
    }
  }
  .allWrapper-dialog {
    .loading-box,
    .availableTimes-wrapper {
      width: 568px;
      height: 200px;
      overflow-y: scroll;
    }
  }
}
// Smart Phone View
@media (max-width: 767px) {
  .bookfilter-forDesktop,
  .event-title {
    display: none;
    font-size: 32px;
  }
  .event-summary {
    padding-bottom: 8px;

    .event-date {
      margin-left: 10px;
      margin-bottom: 0;
    }
  }
  .event-description {
    margin-top: 0;
  }
  .eventContent-wrapper {
    padding: 32px 16px 72px;
  }
}

.icon-container {
  display: inline-block;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: gray;
  position: relative;
  z-index: 1;
}

.icon-image {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  position: relative;
  z-index: 2;
  background-color: #fff;
}

.icon-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: block;
  position: relative;
  z-index: 1;
}

.icon-circle {
  @extend .icon-container;
  position: relative;
  z-index: 1;
  &::before {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: '';
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    background: linear-gradient(45deg, #e3b0b3, #976ad4);
    border-radius: 50%;
  }
}
.InvalidSection {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  content: '';
  top: 0;
  left: 0;
  z-index: 1001;
  background-color: #000;
  opacity: 0.6;

  p {
    color: #fff;
    text-align: center;
    font-size: 20px;
    position: fixed;
    top: 50%;
    z-index: 1002;
    width: 100%;
  }
}
.InvalidPage {
  position: fixed;
  z-index: 1000;

  &::after {
    position: fixed;
    width: 100%;
    height: 100vh;
    content: '';
    top: 0;
    left: 0;
    z-index: 1001;
    background-color: #000;
    opacity: 0.6;
  }
  p {
    color: #fff;
    text-align: center;
    font-size: 20px;
    position: fixed;
    top: 50%;
    z-index: 1002;
    width: 100%;
  }
}

.custom-calendar-icon {
  border-radius: 4px !important;
  padding: 4px 16px !important;
  color: #666 !important;
}

.box-shadow-none * {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
}
