@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.wabi-root {
  min-height: 100vh;
}

.booking-management {
  .wabi-container {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 80px;
    margin: 0 auto 40px;
    padding: 0 40px;
    max-width: 500px;
  }

  .wabi-header-ttl {
    font-family: Inter !important;
    font-size: 32px !important;
    /* font-weight: 600 !important; */
    line-height: 29.05px !important;
    text-align: left;
    padding-top: 40px;
    margin-bottom: 40px;
  }
  .wabi-header {
    padding-bottom: 40px;
    border-bottom: 1px solid #111;
    margin-bottom: 40px;
  }
  .detail-item {
    display: flex;
    gap: 8px;
    margin-bottom: 20px;
  }
  .wabi-title {
    font-family: Inter !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 19.36px !important;
    text-align: left !important;
  }
  .wabi-title-wrapper {
    display: flex;
    gap: 12px;
  }

  .wabi-subtitle {
    font-family: Inter;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 16.94px !important;
    text-align: left !important;
    color: #777 !important;
  }
  .business-icon {
    width: 60px;
    height: 60px;
  }
  .clock {
    width: 26px !important;
    height: 26px !important;
    color: #3478f6 !important;
  }

  .check-step {
    text-align: center;
    display: flex;
    align-items: center;
  }
  .check-icon {
    width: 60px;
    height: 60px;
    background-color: #fff;

    position: relative;
    z-index: 1;
  }

  .wabi-header-status {
    display: flex;
    align-items: center;
    gap: 16px;
    /* justify-content: space-between; */
    margin-top: 20px;

    /* box-shadow: 0 2px 2px 2px #e5e5e5; */
    /* padding: 20px; */
    /* border-radius: 10px; */
  }

  .business-icon {
    max-width: 140px;
    width: 100%;
    height: 140px;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .wabi-header-second {
    font-family: Inter !important;
    font-size: 22px !important;
    font-weight: 600 !important;
    line-height: 1 !important;
    text-align: center;
  }
  .booking-body {
    padding: 0 40px;
  }

  .MuiChip-label {
    padding: 0 !important;
  }
  .icon {
    flex-shrink: 0;
  }
}

.edit-btn-wrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.contact-wrapper {
  padding: 30px 40px 10px;
  border-radius: 10px;
  border: 1px solid;
}
.contact-description {
  margin-bottom: 40px;
}

.MuiButton-contained {
  box-shadow: none !important;
}
.booking-card-wrapper {
  padding: 30px 40px 10px;
  border-radius: 10px;
  border: 1px solid;
  border-color: #888 !important;
}
